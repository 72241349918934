/*!
 * Start Bootstrap - Clean Blog v5.0.9 (https://startbootstrap.com/themes/clean-blog)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-clean-blog/blob/master/LICENSE)
 */

@import url('https://fonts.googleapis.com/css?family=Lora:400,700,400italic,700italic&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800&display=swap');
body {
    font-size: 18px;
    color: #212529;
    font-family: 'Lora', 'Times New Roman', serif;
}

p {
    line-height: 1.5;
    margin: 30px 0;
}

p a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 800;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

a {
    color: #212529;
    transition: all 0.2s;
}

a:focus,
a:hover {
    color: #0085A1;
}

blockquote {
    font-style: italic;
    color: #868e96;
}

.section-heading {
    font-size: 36px;
    font-weight: 700;
    margin-top: 60px;
}

.caption {
    font-size: 14px;
    font-style: italic;
    display: block;
    margin: 0;
    padding: 10px;
    text-align: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

::-moz-selection {
    color: #fff;
    background: #0085A1;
    text-shadow: none;
}

::selection {
    color: #fff;
    background: #0085A1;
    text-shadow: none;
}

img::-moz-selection {
    color: #fff;
    background: transparent;
}

img::selection {
    color: #fff;
    background: transparent;
}

img::-moz-selection {
    color: #fff;
    background: transparent;
}

.container, .container-md, .container-sm {
    max-width: 1300px;
}

@media only screen and (max-width: 768px) {
    body {
        font-size: 16px;
    }
}